<template>
  <div class="wechat">
    <div class="card">
      <van-image class="wechartPng" :src="require('@/assets/wechart.png')" />
      <div class="title">
        <span>微信立减金</span>
      </div>
      <div class="amount">
        <span>1元立减金</span>
      </div>
      <div class="tip">
        <span>微信支付时滿1.01元自动抵扣</span>
      </div>
      <van-button
        style="widht: 60% !important"
        type="primary"
        block
        @click="handleExchange"
        :loading="loading"
        >立即领取</van-button
      >
    </div>
  </div>
</template>

<script>
import { getQueryVariable } from "../utils/index";
import { localStorage } from "../utils/storage";
import { api_exchange, api_getOpenid, api_authoirze } from "../api/clientOrder";
export default {
  name: "",
  data() {
    return {
      notice: [
        "1.微信測試專用",
        "2.微信測試專用",
        "3.微信測試專用",
        "4.微信測試專用",
        "5.微信測試專用",
      ],
      loading: false,
      coupon: "",
      token: "",
      userid: "",
    };
  },
  created() {
    if (getQueryVariable("coupon")) {
      8;
      localStorage.set("coupon", getQueryVariable("coupon"));
      localStorage.set("token", getQueryVariable("token"));
      // this.login();
    }
    // this.getOpenid();
  },
  methods: {
    login() {
      // let urlNow = encodeURIComponent(window.location.origin + window.location.pathname);
      // console.log(window.location.origin + window.location.pathname);
      // let APPID = "wxcf3a5286d3035f73";
      // let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${urlNow}&response_type=code&scope=snsapi_base#wechat_redirect`;
      // window.location.replace(url);
      const params = {
        redirect_url: window.location.href,
      };
      api_authoirze(params).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data);
        }
      });
    },
    getOpenid() {
      const params = {
        code: 521554,
      };
      api_getOpenid(params).then((res) => {
        if (res.data.code == 0) {
          this.userid = res.data.data;
        }
      });
    },
    handleExchange() {
      const params = {
        coupon: localStorage.get("coupon"),
        token: localStorage.get("token"),
        userid: this.userid,
      };
      this.loading = true;
      api_exchange(params).then((res) => {
        if (res.data.code == 0) {
          this.loading = false;
          this.$toast.success(res.data.msg);
        } else {
          this.loading = false;
          this.$toast.fail(res.data.msg);
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.wechat {
  height: 100%;
  background: #63b359;
  padding: 0 20px;
  overflow: scroll;
}
.card {
  margin-top: 7em;
  border-radius: 10px;
  height: 18em;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.wechartPng {
  position: absolute;
  top: -2.5em;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  background-color: #ffffff;
  padding: 1ch;
}
.title {
  font-size: 20px;
  font-weight: 500;
}
.amount {
  font-size: 30px;
  font-weight: 500;
  margin: 0.5ch 0;
  color: red;
}
.tip {
  color: #b1aeae;
  font-size: small;
}
.van-button--block {
  width: 60%;
  margin: 1em 0;
}
.van-button__text {
  font-weight: 500;
}
.notice {
  width: 100%;
  border-top: 1px dashed #ccc;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 10px 0;
}

.notice_title {
  font-weight: 600;
  text-indent: 1.5em;
  line-height: 2em;
  margin-top: 10px;
  
}

.notice_text {
  font-size: 16px;
  text-indent: 1.5em;
  font-weight: 100;
  line-height: 1.5em;
}

.noticeImg {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
  border-radius: 50px;
}
</style>
